// src/apiService.js

import axios from "axios";
import store from "./store/store"; // Import the Redux store to get the token

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token;

    // Skip the token for searchWithBotToken API calls
    if (!config.url.includes("/bot/search_with_bot_token") && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Helper function for content type headers
const jsonContentType = { "Content-Type": "application/json" };
const formDataContentType = { "Content-Type": "multipart/form-data" };

// Authentication APIs
export const login = (credentials) => api.post("/auth/login", credentials);
export const register = (data) => api.post("/auth/register", data);
export const microsoftLogin = () => api.get("/auth/login/microsoft");
export const authCallback = (params) => api.get("/auth/callback", { params });

// Collections APIs
export const getPrivateCollections = () =>
  api.get("/collections/list_collections");
export const createPrivateCollection = (data) =>
  api.post("/collections/create", data);
export const deletePrivateCollection = (collectionId) =>
  api.delete("/collections/delete_collection", {
    params: { collection_id: collectionId },
  });
export const getPublicCollections = () =>
  api.get("/collections/public/collections");
export const createPublicCollection = (data) =>
  api.post("/collections/public/create_collection", data);
export const uploadDocument = (formData) =>
  api.post("/collections/upload", formData, { headers: formDataContentType });
export const processDocument = (data) => api.post("/collections/process", data);
export const uploadJsonData = (data) =>
  api.post("/collections/upload_json", data);
export const uploadPublicDocument = (formData) =>
  api.post("/collections/upload_public_document", formData, {
    headers: formDataContentType,
  });
export const deleteDocuments = (documentIds) =>
  api.delete("/collections/delete_documents", {
    data: { document_ids: documentIds },
    headers: jsonContentType,
  });
export const deletePublicDocument = (documentId) =>
  api.delete("/collections/delete_public_document", {
    params: { document_id: documentId },
  });
export const getDocumentsByCollection = (collectionId) =>
  api.post("/collections/list_private_documents", {
    collection_id: collectionId,
  });
export const listPublicDocuments = () =>
  api.get("/collections/list_public_document");
export const searchPublicDocuments = (query) =>
  api.get("/collections/search", { params: { query } });

// New API for updating collections
export const updateCollection = (payload) =>
  api.put("/collections/update", payload, { headers: jsonContentType });

// Bot Management APIs
export const getBotTokens = () => api.get("/bot/get_bot_tokens");
export const generateBotToken = (formData) =>
  api.post("/bot/generate_bot_token", formData, {
    headers: formDataContentType,
  });
export const generateEmbedCode = (payload) =>
  api.post("/bot/generate_embed_code", payload);
export const deleteEmbedCode = (botToken) =>
  api.delete("/bot/delete_embed_code", {
    data: { bot_token: botToken },
    headers: jsonContentType,
  });
export const fetchEmbedCode = (botToken) =>
  api.get("/bot/get_embed_code", { params: { bot_token: botToken } });
export const getBotTokenDetails = (bot_token) =>
  api.get("/bot/get_bot_token_details", { params: { bot_token } });
export const updateBotToken = (formData) =>
  api.put("/bot/update_bot_token", formData, { headers: formDataContentType });
export const deleteBotToken = (botToken) =>
  api.delete("/bot/delete_bot_token", {
    data: { bot_token: botToken },
    headers: jsonContentType,
  });
export const searchWithBotToken = (data) => {
  const payload = {
    query: data.query,
    conversation_id: data.conversation_id || null,
    token: data.token || null, // Send token instead of username
  };

  console.log("Payload in searchWithBotToken:", payload);

  return api.post("/bot/search_with_bot_token", payload, {
    headers: {
      Authorization: data.bot_token,
      "Content-Type": "application/json",
    },
  });
};

// Conversation APIs
export const getAllConversations = () =>
  api.get("/conversations/get_all_conversations");
export const getConversation = (conversation_id) =>
  api.get("/conversations/get_conversation", { params: { conversation_id } });
export const getConversationsByCollection = (collection_id) =>
  api.get("/conversations/get_conversations_by_collection", {
    params: { collection_id },
  });
export const shareCollectionWithUser = (collectionId, sharedUser, role) =>
  api.post("/collections/share", {
    collection_id: collectionId,
    user: sharedUser,
    role,
  });
export const searchWithConversation = (payload) =>
  api.post("/conversations/search_with_conversation", payload);
export const deleteConversation = (conversation_id) =>
  api.delete("/conversations/delete_conversation", {
    params: { conversation_id },
  });
export const getSharedUsers = (botToken) =>
  api.get("/bot/view_shared_users", { params: { bot_token: botToken } });
export const removeSharedUser = (botToken, sharedUser) =>
  api.post("/bot/remove_shared_user", {
    bot_token: botToken,
    user: sharedUser,
  });
export const shareBot = (payload) =>
  api.post("/bot/share_bot", payload, { headers: jsonContentType });
export const downloadDocument = (documentId) =>
  api.get(`/collections/download/${documentId}`, { responseType: "blob" });

// BotDialog.jsx
import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  Box,
  Text,
  VStack,
  Textarea,
  Select,
  useToast,
  Image,
} from "@chakra-ui/react";
import {
  updateBotToken,
  generateBotToken,
  getPrivateCollections,
} from "../apiService";

const BotDialog = ({
  isOpen,
  onClose,
  isEditMode,
  selectedBotDetails,
  fetchBots,
}) => {
  const [botName, setBotName] = useState("");
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [availableCollections, setAvailableCollections] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [prompt, setPrompt] = useState(
    "You are a helpful assistant that only provides answers based on the provided data. According to the following context, answer the user's query accurately."
  );
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [description, setDescription] = useState("");
  const [iconFile, setIconFile] = useState(null);
  const [iconPreview, setIconPreview] = useState(""); // Store preview URL or icon_url from backend
  const [failedToLoadIcon, setFailedToLoadIcon] = useState(false); // Track icon load failures
  const [showDropdown, setShowDropdown] = useState(false);
  const [isCollectionError, setIsCollectionError] = useState(false);
  const [uiActions, setUiActions] = useState([]); // State for dynamic actions
  const inputRef = useRef(null);
  const toast = useToast();

  useEffect(() => {
    if (isEditMode && selectedBotDetails) {
      setBotName(selectedBotDetails.bot_name);
      const matchedCollections = availableCollections.filter((collection) =>
        selectedBotDetails.collection_ids.includes(collection.collection_id)
      );
      setSelectedCollections(matchedCollections);
      setPrompt(
        selectedBotDetails.prompt ||
          "You are a helpful assistant that only provides answers based on the provided data. According to the following context, answer the user's query accurately."
      );
      setWelcomeMessage(selectedBotDetails.welcome_message || "");
      setDescription(selectedBotDetails.description || "");
      setUiActions(selectedBotDetails.ui_actions || []); // Load existing UI actions
      setIconPreview(selectedBotDetails.icon_url || ""); // Set icon_url if available
      setFailedToLoadIcon(false); // Reset failed load status when opening dialog
    } else {
      // Reset fields for a new bot creation
      setBotName("");
      setSelectedCollections([]);
      setPrompt(
        "You are a helpful assistant that only provides answers based on the provided data. According to the following context, answer the user's query accurately."
      );
      setWelcomeMessage("");
      setDescription("");
      setIconFile(null);
      setIconPreview("");
      setUiActions([]); // Reset actions if creating a new bot
      setFailedToLoadIcon(false); // Reset failed load status
    }
  }, [isEditMode, selectedBotDetails, availableCollections]);

  useEffect(() => {
    fetchCollections();
  }, []);

  const fetchCollections = async () => {
    try {
      const response = await getPrivateCollections();
      setAvailableCollections(response.data);
    } catch (error) {
      console.error("Error fetching collections:", error);
    }
  };

  const handleSelectCollection = (collection) => {
    if (
      !selectedCollections.some(
        (c) => c.collection_id === collection.collection_id
      )
    ) {
      setSelectedCollections([...selectedCollections, collection]);
      setIsCollectionError(false);
    }
    setSearchTerm("");
  };

  const handleRemoveCollection = (collectionId) => {
    setSelectedCollections(
      selectedCollections.filter((c) => c.collection_id !== collectionId)
    );
  };

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIconFile(file);
      setIconPreview(URL.createObjectURL(file));
      setFailedToLoadIcon(false); // Reset load failure state when selecting new file
    }
  };

  const handleAddUiAction = () => {
    setUiActions([
      ...uiActions,
      { type: "button", name: "", action: "", prompt: "" }, // Default new action
    ]);
  };

  const handleRemoveUiAction = (index) => {
    setUiActions(uiActions.filter((_, i) => i !== index));
  };

  const handleUiActionChange = (index, field, value) => {
    const newUiActions = [...uiActions];
    newUiActions[index][field] = value;
    setUiActions(newUiActions);
  };

  const handleSubmit = async () => {
    if (selectedCollections.length === 0) {
      setIsCollectionError(true);
      toast({
        title: "Error",
        description: "Please select at least one collection.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("bot_name", botName);
    selectedCollections.forEach((collection) => {
      formData.append("collection_ids", collection.collection_id);
    });
    formData.append("prompt", prompt);
    formData.append("welcome_message", welcomeMessage);
    formData.append("description", description);
    formData.append("ui_actions", JSON.stringify(uiActions)); // Attach UI actions as JSON
    if (iconFile) {
      formData.append("icon", iconFile);
    }

    try {
      if (isEditMode) {
        formData.append("bot_token", selectedBotDetails.bot_token);
        await updateBotToken(formData);
      } else {
        await generateBotToken(formData);
      }
      fetchBots();
      onClose();
    } catch (error) {
      console.error("Error updating/creating bot:", error);
      toast({
        title: "Error",
        description: "Failed to update/create bot.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleInputClick = () => {
    setShowDropdown(true);
  };

  const handleOutsideClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [showDropdown]);

  const filteredCollections = availableCollections.filter(
    (collection) =>
      collection.collection_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) &&
      !selectedCollections.some(
        (c) => c.collection_id === collection.collection_id
      )
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent bg="#232121" color="white">
        <ModalHeader>{isEditMode ? "Edit Bot" : "Create Bot"}</ModalHeader>
        <ModalCloseButton color="white" _hover={{ bg: "red.600" }} />
        <ModalBody>
          <FormControl>
            <FormLabel color="white">Bot Name</FormLabel>
            <Input
              value={botName}
              onChange={(e) => setBotName(e.target.value)}
              bg="#1c1c1c"
              color="white"
              _placeholder={{ color: "gray.400" }}
            />
          </FormControl>

          <FormControl mt={4} ref={inputRef} isInvalid={isCollectionError}>
            <FormLabel color="white">Collections</FormLabel>
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              p={2}
              bg="#1c1c1c"
              borderRadius="md"
              cursor="pointer"
              onClick={handleInputClick}
              borderColor={isCollectionError ? "red.500" : "transparent"}
              borderWidth="1px"
            >
              {selectedCollections.map((collection) => (
                <Tag
                  size="md"
                  key={collection.collection_id}
                  borderRadius="full"
                  variant="solid"
                  colorScheme="red"
                  mr={2}
                  mb={2}
                >
                  <TagLabel>{collection.collection_name}</TagLabel>
                  <TagCloseButton
                    onClick={() =>
                      handleRemoveCollection(collection.collection_id)
                    }
                  />
                </Tag>
              ))}
              <Input
                placeholder={
                  selectedCollections.length > 0 ? "" : "Search collections"
                }
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                bg="#1c1c1c"
                color="white"
                _placeholder={{ color: "gray.400" }}
                border="none"
                _focus={{ border: "none" }}
                width="auto"
                flex="1"
              />
            </Box>
            {showDropdown && (
              <Box
                bg="#1c1c1c"
                mt={2}
                p={2}
                borderRadius="md"
                position="absolute"
                zIndex="10"
                width="100%"
                maxHeight="150px"
                overflowY="auto"
              >
                {filteredCollections.length === 0 ? (
                  <Text color="gray.400">No collections found</Text>
                ) : (
                  filteredCollections.map((collection) => (
                    <Text
                      key={collection.collection_id}
                      color="white"
                      cursor="pointer"
                      _hover={{ color: "red.600" }}
                      onClick={() => handleSelectCollection(collection)}
                    >
                      {collection.collection_name}
                    </Text>
                  ))
                )}
              </Box>
            )}
          </FormControl>

          <FormControl mt={4}>
            <FormLabel color="white">Prompt</FormLabel>
            <Textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              bg="#1c1c1c"
              color="white"
              _placeholder={{ color: "gray.400" }}
              minHeight="100px"
              resize="vertical"
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel color="white">Welcome Message</FormLabel>
            <Textarea
              value={welcomeMessage}
              onChange={(e) => setWelcomeMessage(e.target.value)}
              bg="#1c1c1c"
              color="white"
              _placeholder={{ color: "gray.400" }}
              minHeight="60px"
              resize="vertical"
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel color="white">Description</FormLabel>
            <Textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              bg="#1c1c1c"
              color="white"
              _placeholder={{ color: "gray.400" }}
              minHeight="60px"
              resize="vertical"
            />
          </FormControl>

          {/* Dynamic UI Actions */}
          <FormControl mt={4}>
            <FormLabel color="white">UI Actions</FormLabel>
            {uiActions.map((action, index) => (
              <Box key={index} mb={2} p={2} bg="#1c1c1c" borderRadius="md">
                <Select
                  placeholder="Select Action Type"
                  value={action.type}
                  onChange={(e) =>
                    handleUiActionChange(index, "type", e.target.value)
                  }
                  bg="#2e2e2e"
                  color="white"
                  mb={2}
                >
                  <option value="button">Button</option>
                  {/* Additional types can be added here */}
                </Select>
                <Input
                  placeholder="Button Name"
                  value={action.name}
                  onChange={(e) =>
                    handleUiActionChange(index, "name", e.target.value)
                  }
                  bg="#2e2e2e"
                  color="white"
                  mb={2}
                />
                <Input
                  placeholder="Button Action"
                  value={action.action}
                  onChange={(e) =>
                    handleUiActionChange(index, "action", e.target.value)
                  }
                  bg="#2e2e2e"
                  color="white"
                  mb={2}
                />
                <Textarea
                  placeholder="Prompt for when to show"
                  value={action.prompt}
                  onChange={(e) =>
                    handleUiActionChange(index, "prompt", e.target.value)
                  }
                  bg="#2e2e2e"
                  color="white"
                  mb={2}
                />
                <Button
                  onClick={() => handleRemoveUiAction(index)}
                  colorScheme="red"
                  size="sm"
                >
                  Remove Action
                </Button>
              </Box>
            ))}
            <Button onClick={handleAddUiAction} colorScheme="blue" mt={2}>
              Add Action
            </Button>
          </FormControl>

          <FormControl mt={4}>
            <FormLabel color="white">Icon Image</FormLabel>
            <Input
              type="file"
              accept="image/*"
              onChange={handleIconChange}
              bg="#1c1c1c"
              color="white"
              _placeholder={{ color: "gray.400" }}
            />
            {/* Conditionally render icon preview only if it hasn't failed to load */}
            {iconPreview && !failedToLoadIcon && (
              <Image
                src={iconPreview}
                alt="Icon Preview"
                mt={2}
                maxHeight="100px"
                onError={() => setFailedToLoadIcon(true)} // Set failed to load if error occurs
              />
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            onClick={onClose}
            color="white"
            _hover={{ bg: "gray.800" }}
          >
            Cancel
          </Button>
          <Button colorScheme="red" onClick={handleSubmit} ml={3}>
            {isEditMode ? "Update Bot" : "Create Bot"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BotDialog;

// DocumentTable.jsx

import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  IconButton,
  Tooltip,
  Text,
  Box,
  Button,
  Spinner,
  Progress,
} from "@chakra-ui/react";
import { DeleteIcon, RepeatIcon } from "@chakra-ui/icons";

const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

const DocumentTable = ({
  documents,
  handleDelete,
  handleProcess,
  handleRefreshStatus,
  isBulkMode,
  selectedDocumentIds,
  handleSelectAll,
  handleSelectOne,
  processingDocumentIds,
  processedDocumentIds,
  documentProgress,
}) => {
  const isAllSelected =
    documents.length > 0 && selectedDocumentIds.length === documents.length;

  return (
    <Box overflowX="auto">
      <Table variant="simple" colorScheme="gray" size="md" minW="800px">
        <Thead>
          <Tr>
            {isBulkMode && (
              <Th>
                <Checkbox
                  isChecked={isAllSelected}
                  onChange={(e) => handleSelectAll(e.target.checked)}
                  aria-label="Select All Documents"
                />
              </Th>
            )}
            <Th>File Name</Th>
            <Th>Added By</Th>
            <Th>File Size</Th>
            <Th>Upload Time</Th>
            <Th>Actions</Th>
            <Th>Progress</Th>
          </Tr>
        </Thead>
        <Tbody>
          {documents.map((doc) => {
            const isSelected = selectedDocumentIds.includes(doc.document_id);
            const displayFileName = doc.file_name || doc.original_file_name || "N/A";
            const isProcessing = processingDocumentIds.includes(doc.document_id);
            const isProcessed = processedDocumentIds.includes(doc.document_id);
            const progressData = documentProgress[doc.document_id] || {};
            const progress = progressData.progress || 0;
            const status = progressData.status || "";

            return (
              <Tr key={doc.document_id}>
                {isBulkMode && (
                  <Td>
                    <Checkbox
                      isChecked={isSelected}
                      onChange={() => handleSelectOne(doc.document_id)}
                      aria-label={`Select Document ${displayFileName}`}
                    />
                  </Td>
                )}
                <Td>
                  <Tooltip label={displayFileName} aria-label="File Name Tooltip">
                    <Text isTruncated maxW="200px">{displayFileName}</Text>
                  </Tooltip>
                </Td>
                <Td>
                  <Tooltip label={doc.added_by} aria-label="Added By Tooltip">
                    <Text isTruncated maxW="150px">{doc.added_by}</Text>
                  </Tooltip>
                </Td>
                <Td>{formatFileSize(doc.file_size)}</Td>
                <Td>
                  {new Date(doc.upload_time).toLocaleString(undefined, {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Td>
                <Td>
                  <Tooltip label="Delete Document" aria-label="Delete Document Tooltip">
                    <IconButton
                      icon={<DeleteIcon />}
                      onClick={() => handleDelete(doc.document_id)}
                      variant="ghost"
                      aria-label={`Delete Document ${displayFileName}`}
                      size="sm"
                      color="red.600"
                      _hover={{ color: "white", bg: "red.600" }}
                      isDisabled={isProcessing}
                    />
                  </Tooltip>

                  {isProcessing ? (
                    <Button colorScheme="blue" size="sm" ml={3} isDisabled leftIcon={<Spinner size="sm" />}>
                      Processing...
                    </Button>
                  ) : isProcessed ? (
                    <Button colorScheme="green" size="sm" ml={3} isDisabled>
                      Processed
                    </Button>
                  ) : (
                    <Button
                      colorScheme="blue"
                      size="sm"
                      ml={3}
                      onClick={() => handleProcess(doc.document_id)}
                    >
                      Process
                    </Button>
                  )}

                  <Tooltip label="Refresh Status" aria-label="Refresh Status Tooltip">
                    <IconButton
                      icon={<RepeatIcon />}
                      onClick={() => handleRefreshStatus(doc.document_id)}
                      variant="ghost"
                      aria-label="Refresh Status"
                      size="sm"
                      ml={3}
                    />
                  </Tooltip>
                </Td>
                <Td width="250px">
                  {isProcessing && (
                    <Box>
                      <Progress
                        value={progress}
                        size="sm"
                        colorScheme="blue"
                        width="100%"
                        borderRadius="md"
                        transition="width 0.5s ease, background-color 0.5s ease"
                      />
                      <Text
                        fontSize="sm"
                        mt={1}
                        textAlign="center"
                        transition="opacity 0.5s ease"
                        opacity={progress < 100 ? 1 : 0}
                      >
                        {progress}% - {status}
                      </Text>
                    </Box>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default DocumentTable;

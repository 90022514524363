import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Text,
  Button,
  Input,
  Heading,
  useToast,
  Flex,
} from "@chakra-ui/react";
import {
  getDocumentsByCollection,
  deleteDocuments,
  uploadDocument,
  processDocument,
  downloadDocument,
} from "../apiService";
import UploadDialog from "./UploadDialog";
import DocumentTable from "./DocumentTable";
import PublicUploadDialog from "./PublicUploadDialog";
import { UploadProvider, useUpload } from "./UploadContext";
import { io } from "socket.io-client";

const KnowledgeBaseDetails = () => {
  const { collectionId } = useParams();
  const {
    selectedFiles,
    setSelectedFiles,
    fileType,
    setFileType,
    url,
    setUrl,
    tabIndex,
  } = useUpload();

  const [documents, setDocuments] = useState([]);
  const [processingDocumentIds, setProcessingDocumentIds] = useState([]);
  const [processedDocumentIds, setProcessedDocumentIds] = useState([]);
  const [documentProgress, setDocumentProgress] = useState({});
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isPublicUploadModalOpen, setIsPublicUploadModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [isBulkMode, setIsBulkMode] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const toast = useToast();
  const isPublic = localStorage.getItem("is_public") === "true";

  const openUploadModal = () => setIsUploadModalOpen(true);
  const openPublicUploadModal = () => setIsPublicUploadModalOpen(true);

  useEffect(() => {
    fetchDocuments();

    const socket = io("http://localhost:5000", {
      transports: ["websocket"],
      withCredentials: true,
    });

    socket.on("connect", () => console.log("Connected to server"));
    socket.on("disconnect", () => console.log("Disconnected from server"));

    socket.on("processing_update", (data) => {
      const { document_id, status, progress, error } = data;

      setDocumentProgress((prev) => ({
        ...prev,
        [document_id]: { progress, targetProgress: progress, status },
      }));

      if (status === "Processing completed") {
        setProcessedDocumentIds((prev) => [...prev, document_id]);
        setProcessingDocumentIds((prev) =>
          prev.filter((id) => id !== document_id)
        );
        toast({
          title: `Document ${document_id} processed successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchDocuments();
      } else if (status === "Error during processing") {
        setProcessingDocumentIds((prev) =>
          prev.filter((id) => id !== document_id)
        );
        toast({
          title: `Error processing document ${document_id}`,
          description: error,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [collectionId, toast]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDocumentProgress((prevProgress) => {
        const updatedProgress = {};
        for (const [docId, data] of Object.entries(prevProgress)) {
          const { progress, targetProgress, status } = data;
          if (progress < targetProgress) {
            updatedProgress[docId] = { ...data, progress: progress + 1 };
          } else if (progress === 100 && status === "Processing completed") {
            updatedProgress[docId] = {
              ...data,
              progress: 100,
              completed: true,
            };
          } else {
            updatedProgress[docId] = { ...data, progress: targetProgress };
          }
        }
        return { ...prevProgress, ...updatedProgress };
      });
    }, 100);
    return () => clearInterval(interval);
  }, []);

  const fetchDocuments = async () => {
    try {
      const response = await getDocumentsByCollection(collectionId);
      const documentsData = response.data.documents || response.data || [];
      setDocuments(documentsData);

      const newProgress = {};
      const processingIds = [];
      const processedIds = [];
      documentsData.forEach((doc) => {
        if (doc.is_processing) {
          processingIds.push(doc.document_id);
          newProgress[doc.document_id] = documentProgress[doc.document_id] || {
            progress: 0,
            status: "Processing",
          };
        } else if (doc.processed) {
          processedIds.push(doc.document_id);
        }
      });

      setProcessingDocumentIds(processingIds);
      setProcessedDocumentIds(processedIds);
      setDocumentProgress((prev) => ({ ...prev, ...newProgress }));
    } catch (error) {
      toast({
        title: "Error fetching documents",
        description:
          error.message || "An error occurred while fetching documents.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setDocuments([]);
    }
  };

  const handleDelete = async (documentId) => {
    try {
      setIsDeleting(true);
      await deleteDocuments([documentId]);
      setDocuments((prevDocuments) =>
        prevDocuments.filter((doc) => doc.document_id !== documentId)
      );
      toast({
        title: "Document deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Failed to delete document",
        description:
          error.response?.data?.error ||
          "An error occurred while deleting the document.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleProcess = async (documentId) => {
    setProcessingDocumentIds((prev) => [...prev, documentId]);
    try {
      await processDocument({ document_id: documentId });
      toast({
        title: "Document processing started",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error initiating document processing",
        description:
          error.message || "An error occurred while processing the document.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setProcessingDocumentIds((prev) =>
        prev.filter((id) => id !== documentId)
      );
    }
  };

  const handleDownload = async (documentId, originalFileName) => {
    try {
      const response = await downloadDocument(documentId);
      const fileName = originalFileName || "document";
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toast({
        title: "Error downloading document",
        description:
          error.message || "An error occurred while downloading the document.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
    setSelectedFiles([]);
    setUrl("");
  };

  const closePublicUploadModal = () => {
    setIsPublicUploadModalOpen(false);
    setSelectedFiles([]);
  };

  const handleUpload = async () => {
    if (tabIndex === 0 && selectedFiles.length === 0) {
      toast({
        title: "No file selected",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (tabIndex === 1 && !url) {
      toast({
        title: "No URL provided",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    if (tabIndex === 0) {
      for (const file of selectedFiles) {
        formData.append("file", file);
        const fileExtension = file.name.split(".").pop();
        setFileType(fileExtension);
        formData.append("file_type", fileExtension);
      }
    } else {
      formData.append("url", url);
      formData.append("file_type", fileType);
    }
    formData.append("collection_id", collectionId);

    try {
      await uploadDocument(formData);
      toast({
        title: "Document uploaded successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setIsUploadModalOpen(false);
      setSelectedFiles([]);
      setUrl("");
      fetchDocuments();
    } catch (error) {
      toast({
        title: "Error uploading document",
        description:
          error.message || "An error occurred while uploading the document.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      padding={8}
      paddingTop={"4.1rem"}
      minHeight="100vh"
      bg="#232121"
      color="white"
    >
      <Heading as="h2" size="xl" mb={4}>
        Dataset
      </Heading>
      <Text mb={6}>
        Questions and answers can only be answered after parsing is successful.
      </Text>
      <Flex mb={6} justifyContent="space-between" alignItems="center">
        <Button
          colorScheme="blue"
          onClick={() => setIsBulkMode(!isBulkMode)}
          mr={4}
          isDisabled={isDeleting}
        >
          {isBulkMode ? "Cancel Bulk Mode" : "Bulk"}
        </Button>
        <Input
          placeholder="Search your files"
          width="300px"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          bg="#232121"
          color="white"
          _placeholder={{ color: "gray.400" }}
          mr={4}
          isDisabled={isDeleting}
        />
        <Button
          colorScheme="red"
          bg="red.600"
          _hover={{ bg: "red.700" }}
          onClick={isPublic ? openPublicUploadModal : openUploadModal}
          isDisabled={isDeleting}
        >
          {isPublic ? "+ Add Public Document" : "+ Add File"}
        </Button>
      </Flex>

      <DocumentTable
        documents={documents}
        handleDelete={handleDelete}
        handleProcess={handleProcess}
        handleDownload={handleDownload}
        isBulkMode={isBulkMode}
        selectedDocumentIds={selectedDocumentIds}
        handleSelectAll={(isChecked) =>
          setSelectedDocumentIds(
            isChecked ? documents.map((doc) => doc.document_id) : []
          )
        }
        handleSelectOne={(documentId) =>
          setSelectedDocumentIds((prev) =>
            prev.includes(documentId)
              ? prev.filter((id) => id !== documentId)
              : [...prev, documentId]
          )
        }
        processingDocumentIds={processingDocumentIds}
        processedDocumentIds={processedDocumentIds}
        documentProgress={documentProgress}
      />

      <UploadDialog
        key={`upload-${isUploadModalOpen}`}
        isOpen={isUploadModalOpen}
        onClose={closeUploadModal}
        handleFileChange={(e) => setSelectedFiles(Array.from(e.target.files))}
        handleUpload={handleUpload}
        removeFile={(index) =>
          setSelectedFiles((prevFiles) =>
            prevFiles.filter((_, i) => i !== index)
          )
        }
      />
      <PublicUploadDialog
        key={`public-upload-${isPublicUploadModalOpen}`}
        isOpen={isPublicUploadModalOpen}
        onClose={closePublicUploadModal}
        handleFileChange={(e) => setSelectedFiles(Array.from(e.target.files))}
        handleUpload={handleUpload}
        selectedFiles={selectedFiles}
        removeFile={(index) =>
          setSelectedFiles((prevFiles) =>
            prevFiles.filter((_, i) => i !== index)
          )
        }
      />
    </Box>
  );
};

export default () => (
  <UploadProvider>
    <KnowledgeBaseDetails />
  </UploadProvider>
);

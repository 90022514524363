import React, { useEffect, useRef } from "react";
import { VStack, HStack, Box, Avatar, Button, Input, FormControl, FormLabel } from "@chakra-ui/react";
import { FaUser } from "react-icons/fa"; // User icon
import { motion } from "framer-motion"; // For animation effects

// Define motion components
const MotionBox = motion(Box);

// Animation Variants
const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.05, // Controls typing speed
    },
  },
};

const childVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

// BotMessage Component with typing effect and interactive elements (buttons, form)
const BotMessage = ({ content, buttons, form, handleButtonClick, handleFormSubmit }) => {
  const characters = content.split(""); // Split content into individual characters for typing effect

  return (
    <>
      <MotionBox
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        display="inline"
        style={{
          overflowWrap: "break-word",
          wordBreak: "break-word",
          whiteSpace: "normal",
        }}
      >
        {characters.map((char, index) => (
          <MotionBox key={index} as="span" variants={childVariants}>
            {char}
          </MotionBox>
        ))}
      </MotionBox>

      {/* Render buttons if present */}
      {buttons && buttons.length > 0 && (
        <Box mt={2}>
          {buttons.map((button, idx) => (
            <Button key={idx} onClick={() => handleButtonClick(button)} mr={2} mb={2}>
              {button.label}
            </Button>
          ))}
        </Box>
      )}

      {/* Render form if present */}
      {form && form.length > 0 && (
        <Box mt={4}>
          <form onSubmit={(e) => handleFormSubmit(e, form)}>
            {form.map((field, idx) => (
              <FormControl key={idx} mb={3} isRequired={field.required}>
                <FormLabel>{field.label}</FormLabel>
                <Input
                  type={field.type || "text"}
                  name={field.name}
                  placeholder={field.placeholder}
                  defaultValue={field.defaultValue || ""}
                />
              </FormControl>
            ))}
            <Button type="submit" colorScheme="teal">
              Submit
            </Button>
          </form>
        </Box>
      )}
    </>
  );
};

const MessagesDisplay = ({ messages, isTyping, botDetails, handleButtonClick, handleFormSubmit }) => {
  const messagesEndRef = useRef(null);

  // Scroll to the bottom when a new message is added or typing starts
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isTyping]);

  return (
    <VStack
      flex="1"
      overflowY="auto" // Make the messages area scrollable
      spacing={4}
      bg="white"
      width="100%"
      paddingX="4"
      paddingTop="6" // Added padding to move messages down
    >
      {messages.map((msg, index) => (
        <HStack
          key={index}
          alignSelf={msg.role === "user" ? "flex-end" : "flex-start"}
          bg={msg.role === "user" ? "blue.500" : "gray.100"}
          color={msg.role === "user" ? "white" : "black"}
          borderRadius="lg"
          p={3}
          maxWidth="75%"
          boxShadow="md"
          flexDirection={msg.role === "user" ? "row-reverse" : "row"}
          alignItems="flex-start"
          spacing={2}
        >
          {/* Avatar */}
          {msg.role === "user" ? (
            <Avatar icon={<FaUser />} bg="blue.600" color="white" size="sm" mt={1} />
          ) : (
            <Avatar
              size="sm"
              src={botDetails?.icon_base64 ? `data:image/png;base64,${botDetails.icon_base64}` : undefined}
              name="Bot"
              mt={1}
            />
          )}

          {/* Message Content */}
          <Box
            bg={msg.role === "user" ? "blue.500" : "gray.100"}
            color={msg.role === "user" ? "white" : "black"}
            borderRadius="lg"
            p={2}
            maxWidth="100%"
            style={{
              overflowWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "normal",
            }}
          >
            {msg.role === "user" ? (
              <Box as="span">{msg.content}</Box>
            ) : (
              <BotMessage
                content={msg.content}
                buttons={msg.buttons}
                form={msg.form}
                handleButtonClick={handleButtonClick}
                handleFormSubmit={handleFormSubmit}
              />
            )}
          </Box>
        </HStack>
      ))}

      {/* Typing Indicator */}
      {isTyping && (
        <HStack
          alignSelf="flex-start"
          bg="gray.100"
          borderRadius="lg"
          p={3}
          maxWidth="75%"
          boxShadow="md"
          alignItems="center"
          spacing={2}
        >
          <Avatar
            size="sm"
            src={botDetails?.icon_base64 ? `data:image/png;base64,${botDetails.icon_base64}` : undefined}
            name="Bot"
            mt={1}
          />
          {/* Three-dot typing indicator */}
          <HStack>
            <MotionBox
              width="8px"
              height="8px"
              bg="gray"
              borderRadius="50%"
              animate={{
                opacity: [0.4, 1, 0.4],
                y: [0, -5, 0],
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                ease: "easeInOut",
                delay: 0,
              }}
            />
            <MotionBox
              width="8px"
              height="8px"
              bg="gray"
              borderRadius="50%"
              animate={{
                opacity: [0.4, 1, 0.4],
                y: [0, -5, 0],
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                ease: "easeInOut",
                delay: 0.2,
              }}
            />
            <MotionBox
              width="8px"
              height="8px"
              bg="gray"
              borderRadius="50%"
              animate={{
                opacity: [0.4, 1, 0.4],
                y: [0, -5, 0],
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                ease: "easeInOut",
                delay: 0.4,
              }}
            />
          </HStack>
        </HStack>
      )}

      {/* Scroll to the latest message */}
      <div ref={messagesEndRef} />
    </VStack>
  );
};

export default MessagesDisplay;

import React, { useState, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  Heading,
  Input,
  Button,
  Card,
  CardHeader,
  CardBody,
  Avatar,
  VStack,
  HStack,
  Tooltip,
  IconButton,
  Icon,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Select,
  List,
  ListItem,
} from "@chakra-ui/react";
import {
  getPrivateCollections,
  deletePrivateCollection,
  shareCollectionWithUser,
  getSharedUsers,
  removeSharedUser,
  updateCollection, // Newly added import
} from "../apiService";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { setCollectionId } from "../store/actions/collectionActions";
import {
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaFileAlt,
  FaTrash,
  FaUser,
  FaUsers,
  FaEdit,
} from "react-icons/fa";
import CreateKnowledgeBaseDialog from "./CreateKnowledgeBaseDialog";

const KnowledgeBase = () => {
  const [collections, setCollections] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [sharingCollection, setSharingCollection] = useState(null);
  const [sharedUsers, setSharedUsers] = useState([]);
  const [newSharedUser, setNewSharedUser] = useState("");
  const [newUserRole, setNewUserRole] = useState("read-only");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // State for editing collections
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [newCollectionName, setNewCollectionName] = useState("");

  const {
    isOpen: isUsersModalOpen,
    onOpen: onUsersModalOpen,
    onClose: onUsersModalClose,
  } = useDisclosure();

  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const token = useSelector((state) => state.auth.token);

  let user = null;
  let fullName = "";

  if (token) {
    const decodedToken = jwtDecode(token);
    user = {
      loginId: decodedToken.LoginId,
      userName: decodedToken.UserName,
      fullName: decodedToken.FullName,
    };
    fullName = user.fullName;
  }

  useEffect(() => {
    fetchCollections();
  }, []);

  const fetchCollections = async () => {
    try {
      const response = await getPrivateCollections();
      setCollections(response.data);
    } catch (error) {
      console.error("Error fetching collections:", error);
    }
  };

  const handleCollectionClick = (collection) => {
    if (isDeleting) return;

    const { collection_id, public: isPublic } = collection;
    dispatch(setCollectionId(collection_id));
    localStorage.setItem("collection_id", collection_id);
    localStorage.setItem("is_public", isPublic);
    navigate(`/knowledgebase/details/${collection_id}`);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDeleteClick = async (collection) => {
    try {
      setIsDeleting(true);
      await deletePrivateCollection(collection.collection_id);
      toast({
        title: "Deleted",
        description: `${collection.collection_name} deleted successfully.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchCollections();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete the collection.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSharedUsersClick = async (collection) => {
    try {
      setSharingCollection(collection);
      const response = await getSharedUsers(collection.collection_id);
      setSharedUsers(response.data.shared_users);
      onUsersModalOpen();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to load shared users.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRemoveSharedUser = async (userToRemove) => {
    try {
      await removeSharedUser(
        sharingCollection.collection_id,
        userToRemove.username
      );
      toast({
        title: "Removed",
        description: `User ${userToRemove.username} removed from the collection.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setSharedUsers((prev) =>
        prev.filter((user) => user.username !== userToRemove.username)
      );
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to remove the user.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddSharedUser = async () => {
    if (!sharingCollection || !newSharedUser) return;

    try {
      await shareCollectionWithUser(
        sharingCollection.collection_id,
        newSharedUser,
        newUserRole
      );

      toast({
        title: "Shared",
        description: `Collection shared with ${newSharedUser} as ${newUserRole}.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setSharedUsers((prev) => [
        ...prev,
        { username: newSharedUser, role: newUserRole },
      ]);

      setNewSharedUser("");
      setNewUserRole("read-only");
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to share the collection.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditClick = (collection) => {
    setSelectedCollection(collection);
    setNewCollectionName(collection.collection_name);
    onEditModalOpen();
  };

  const handleUpdateCollection = async () => {
    if (!selectedCollection) return;

    try {
      const updatedData = {
        collection_id: selectedCollection.collection_id,
        collection_name: newCollectionName,
        public: selectedCollection.public,
      };

      await updateCollection(updatedData);

      toast({
        title: "Updated",
        description: "Collection updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      fetchCollections();
      onEditModalClose();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update the collection.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const filteredCollections = collections.filter((collection) =>
    collection.collection_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const fileTypeIcons = {
    pdf: FaFilePdf,
    doc: FaFileWord,
    docx: FaFileWord,
    xls: FaFileExcel,
    xlsx: FaFileExcel,
    txt: FaFileAlt,
    default: FaFileAlt,
  };

  return (
    <Box
      padding={8}
      bg="#232121"
      color="white"
      flex="1"
      display="flex"
      flexDirection="column"
      minHeight="calc(100vh - 3.6rem)"
    >
      <Heading as="h2" size="xl" mb={4} paddingTop="4rem">
        Welcome back, {fullName}
      </Heading>
      <Box
        mb={6}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Input
          placeholder="Search"
          width="20rem"
          borderRadius="md"
          bg="rgba(0, 0, 0, 0.17)"
          color="white"
          _placeholder={{ color: "gray.400" }}
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Button
          colorScheme="red"
          bg="red.600"
          _hover={{ bg: "red.700" }}
          onClick={() => setIsDialogOpen(true)}
        >
          + Create knowledge base
        </Button>
      </Box>
      <Box flex="1" overflowY="auto">
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
          {filteredCollections.map((collection) => {
            const documentCount = collection.document_count || 0;
            const fileTypes = collection.file_types || {};

            return (
              <Card
                key={collection.collection_id}
                onClick={() => handleCollectionClick(collection)}
                cursor={isDeleting ? "not-allowed" : "pointer"}
                bg="rgba(0, 0, 0, 0.17)"
                _hover={!isDeleting && { bg: "gray.800" }}
                color="white"
                borderRadius="md"
                height="14rem"
                display="flex"
                flexDirection="column"
                width="100%"
                position="relative"
                pointerEvents={isDeleting ? "none" : "auto"}
              >
                <CardHeader>
                  <HStack spacing={4} alignItems="flex-start" width="100%">
                    <Avatar size="md" name={collection.collection_name} />
                    <VStack align="start" spacing={0} flex="1">
                      <Tooltip label={collection.collection_name} hasArrow>
                        <Text
                          fontSize="2xl"
                          fontWeight="bold"
                          noOfLines={1}
                          width="100%"
                        >
                          {collection.collection_name}
                        </Text>
                      </Tooltip>
                      <Text fontSize="sm" color="gray.400">
                        Created by: {collection.created_by}
                      </Text>
                      <Text fontSize="sm" color="gray.400">
                        Created on:{" "}
                        {new Date(collection.created_at).toLocaleDateString()}
                      </Text>
                    </VStack>
                    <IconButton
                      aria-label="View Shared Users"
                      icon={<FaUsers />}
                      size="sm"
                      colorScheme="blue"
                      variant="ghost"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSharedUsersClick(collection);
                      }}
                      isDisabled={isDeleting}
                      position="absolute"
                      top="1rem"
                      right="3rem"
                    />
                    <IconButton
                      aria-label="Delete Collection"
                      icon={<FaTrash />}
                      size="sm"
                      colorScheme="red"
                      variant="ghost"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteClick(collection);
                      }}
                      isDisabled={isDeleting}
                      position="absolute"
                      top="1rem"
                      right="1rem"
                    />
                    <IconButton
                      aria-label="Edit Collection"
                      icon={<FaEdit />}
                      size="sm"
                      colorScheme="green"
                      variant="ghost"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditClick(collection);
                      }}
                      isDisabled={isDeleting}
                      position="absolute"
                      top="1rem"
                      right="5rem"
                    />
                  </HStack>
                </CardHeader>
                <CardBody flex="1">
                  <VStack align="start" spacing={2}>
                    <Text fontSize="md" fontWeight="semibold">
                      {documentCount}{" "}
                      {documentCount === 1 ? "Document" : "Documents"}
                    </Text>
                    <HStack spacing={2}>
                      {Object.entries(fileTypes).map(([extension, count]) => {
                        const IconComponent =
                          fileTypeIcons[extension.toLowerCase()] ||
                          fileTypeIcons["default"];
                        return (
                          <Tooltip
                            key={extension}
                            label={`${count} ${extension.toUpperCase()} ${
                              count === 1 ? "file" : "files"
                            }`}
                          >
                            <HStack spacing={1}>
                              <Icon as={IconComponent} />
                              <Text>{count}</Text>
                            </HStack>
                          </Tooltip>
                        );
                      })}
                    </HStack>
                  </VStack>
                </CardBody>
              </Card>
            );
          })}
        </SimpleGrid>
        {filteredCollections.length === 0 && (
          <Box textAlign="center" mt={10}>
            <Text fontSize="lg" color="gray.400">
              No collections found.
            </Text>
          </Box>
        )}
      </Box>

      {/* Shared Users Modal */}
      <Modal isOpen={isUsersModalOpen} onClose={onUsersModalClose}>
        <ModalOverlay />
        <ModalContent bg="#232121" color="white">
          <ModalHeader>Shared Users</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <List spacing={3}>
              {sharedUsers.map((user) => (
                <ListItem
                  key={user.username}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text>
                    {user.username} ({user.role})
                  </Text>
                  <IconButton
                    icon={<FaTrash />}
                    colorScheme="red"
                    onClick={() => handleRemoveSharedUser(user)}
                    aria-label="Remove User"
                  />
                </ListItem>
              ))}
            </List>
            <FormControl mt={4}>
              <FormLabel>Add User</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<FaUser color="gray.300" />}
                />
                <Input
                  placeholder="Enter username or email"
                  value={newSharedUser}
                  onChange={(e) => setNewSharedUser(e.target.value)}
                />
              </InputGroup>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Select Role</FormLabel>
              <Select
                value={newUserRole}
                onChange={(e) => setNewUserRole(e.target.value)}
              >
                <option value="admin">Admin</option>
                <option value="contributor">Contributor</option>
                <option value="read-only">Read-Only</option>
              </Select>
            </FormControl>
            <Button colorScheme="blue" mt={4} onClick={handleAddSharedUser}>
              Add User
            </Button>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onUsersModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Edit Collection Modal */}
      <Modal isOpen={isEditModalOpen} onClose={onEditModalClose}>
        <ModalOverlay />
        <ModalContent bg="#232121" color="white">
          <ModalHeader>Edit Collection</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Collection Name</FormLabel>
              <Input
                value={newCollectionName}
                onChange={(e) => setNewCollectionName(e.target.value)}
                placeholder="Enter new collection name"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleUpdateCollection}>
              Update
            </Button>
            <Button variant="ghost" onClick={onEditModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Create Knowledge Base Dialog */}
      <CreateKnowledgeBaseDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        fetchCollections={fetchCollections}
      />
    </Box>
  );
};

export default KnowledgeBase;

// Bots.jsx
import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Card,
  CardHeader,
  CardBody,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import {
  getBotTokens,
  searchWithBotToken,
  deleteBotToken,
  getBotTokenDetails,
  generateEmbedCode,
  deleteEmbedCode,
  fetchEmbedCode,
} from "../apiService";
import BotsList from "./BotsList";
import MessagesDisplay from "./Conversation/MessagesDisplay";
import MessageInput from "./Conversation/MessageInput";
import BotDialog from "./BotDialog";

const Bots = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [bots, setBots] = useState([]);
  const [selectedBotToken, setSelectedBotToken] = useState(null);
  const [selectedBotDetails, setSelectedBotDetails] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const toast = useToast();

  useEffect(() => {
    fetchBots();
  }, []);

  const fetchBots = async () => {
    try {
      const response = await getBotTokens();
      setBots(response.data.tokens);
    } catch (error) {
      console.error("Error fetching bots:", error);
      toast({
        title: "Error",
        description: "Failed to fetch bots.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchBotDetails = async (bot_token) => {
    try {
      const response = await getBotTokenDetails(bot_token);
      setSelectedBotDetails(response.data.bot_details);
      setSelectedBotToken(bot_token);
      setMessages([]);

      if (response.data.bot_details.welcome_message) {
        setMessages([
          {
            content: response.data.bot_details.welcome_message,
            role: "bot",
          },
        ]);
      }
    } catch (error) {
      console.error("Error fetching bot details:", error);
    }
  };

  const handleNewBot = () => {
    setIsEditMode(false);
    setSelectedBotDetails(null);
    setIsDialogOpen(true);
  };

  const handleEditBot = (bot_token) => {
    fetchBotDetails(bot_token);
    setIsEditMode(true);
    setIsDialogOpen(true);
  };

  const handleSendMessage = async () => {
    if (
      currentMessage.trim() &&
      selectedBotToken &&
      selectedBotDetails?.collection_ids
    ) {
      setIsSending(true);
      const userMessage = { content: currentMessage, role: "user" };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setCurrentMessage("");

      try {
        const response = await searchWithBotToken({
          query: currentMessage.trim(),
          bot_token: selectedBotToken,
        });

        setIsTyping(true);
        setTimeout(() => {
          const botMessage = {
            content: response.data.response || "No response from bot",
            role: "bot",
          };
          setMessages((prevMessages) => [...prevMessages, botMessage]);
          setIsTyping(false);
          setIsSending(false);
        }, 1000);
      } catch (error) {
        console.error("Error sending message:", error);
        setIsSending(false);
        setIsTyping(false);
      }
    }
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleDeleteBot = async (bot_token) => {
    try {
      await deleteBotToken(bot_token);
      setBots((prevBots) =>
        prevBots.filter((bot) => bot.bot_token !== bot_token)
      );
      setMessages([]);
      setSelectedBotToken(null);
      toast({
        title: "Bot deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting bot:", error);
      toast({
        title: "Error",
        description: "Failed to delete bot.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleGenerateEmbedCode = async (botToken, setEmbedCode) => {
    try {
      const domain = window.location.origin;
      const response = await generateEmbedCode({
        bot_token: botToken,
        conversation_page_url: domain,
      });

      if (response.data.embed_code) {
        setEmbedCode(response.data.embed_code);
      } else {
        throw new Error("Failed to generate embed code");
      }
    } catch (error) {
      console.error("Error generating embed code:", error);
      toast({
        title: "Error",
        description: "Failed to generate embed code.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteEmbedCode = async (botToken, onSuccess) => {
    try {
      await deleteEmbedCode(botToken);
      onSuccess();
      toast({
        title: "Embed code deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting embed code:", error);
      toast({
        title: "Error",
        description: "Failed to delete embed code.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      height="calc(100vh - 3.6rem)"
      paddingTop="4.1rem"
      backgroundColor="#232121"
      overflow="hidden"
    >
      <BotsList
        bots={bots}
        fetchBotConversation={fetchBotDetails}
        handleDeleteBot={handleDeleteBot}
        handleEditBot={handleEditBot}
        handleNewBot={handleNewBot}
        collapsed={collapsed}
        handleGenerateEmbedCode={handleGenerateEmbedCode}
        handleDeleteEmbedCode={handleDeleteEmbedCode}
        fetchEmbedCode={fetchEmbedCode}
      />

      <Box flex="1" padding={4}>
        <Card height="100%" bg="#232121" color="white">
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <IconButton
                onClick={toggleCollapse}
                icon={collapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}
                variant="outline"
                aria-label="Toggle sidebar"
                color="white"
                bg="rgb(0 0 0 / 17%)"
                _hover={{ bg: "gray.800" }}
              />
              {selectedBotToken
                ? `Bot: ${selectedBotDetails?.bot_name}`
                : "Select a Bot"}
            </Flex>
          </CardHeader>
          <CardBody display="flex" flexDirection="column" overflowY="auto">
            <MessagesDisplay messages={messages} isTyping={isTyping} />
            {selectedBotToken && (
              <MessageInput
                currentMessage={currentMessage}
                setCurrentMessage={setCurrentMessage}
                handleSendMessage={handleSendMessage}
                isSending={isSending}
              />
            )}
          </CardBody>
        </Card>
      </Box>
      <BotDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        isEditMode={isEditMode}
        selectedBotDetails={selectedBotDetails}
        fetchBots={fetchBots}
      />
    </Flex>
  );
};

export default Bots;

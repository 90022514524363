import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Button,
  Spinner,
  Textarea,
  useToast,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import MessagesDisplay from "./MessagesDisplay";
import { searchWithBotToken, getBotTokenDetails } from "../../apiService";
import { useLocation } from "react-router-dom";
import { FaRobot } from "react-icons/fa"; // Importing a default icon for bot

const BotEmbedConversation = () => {
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [botToken, setBotToken] = useState(null);
  const [botDetails, setBotDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Loading state to show spinner until data is fetched
  const [iframeUrl, setIframeUrl] = useState(null); // URL to display in iframe
  const [initialMessage, setInitialMessage] = useState(null); // State to hold the initial message
  const toast = useToast();
  const location = useLocation();

  const textareaRef = useRef(null);

  // Function to adjust the height of the Textarea dynamically
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to auto to shrink if needed
      textarea.style.height = `${Math.min(textarea.scrollHeight, 150)}px`; // Set height with a maximum of 150px
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const botTokenFromURL = params.get("bot_token");
    const initialMessageFromURL = params.get("initial_message"); // Get initial_message from URL
    if (botTokenFromURL) {
      setBotToken(botTokenFromURL);
      if (initialMessageFromURL) {
        setInitialMessage(initialMessageFromURL); // Set initial message
      }
      fetchBotDetails(botTokenFromURL, initialMessageFromURL);
    } else {
      toast({
        title: "Error",
        description: "Bot token is required to load the conversation.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [location]);

  const fetchBotDetails = async (botToken, initialMessage) => {
    try {
      const response = await getBotTokenDetails(botToken);
      setBotDetails(response.data.bot_details);
      if (!initialMessage) {
        // Show welcome message only if there's no initial message
        showWelcomeMessage(response.data.bot_details);
      }
      setIsLoading(false); // Set loading to false after bot details are fetched
    } catch (error) {
      console.error("Error fetching bot details:", error);
      toast({
        title: "Error",
        description: "Failed to fetch bot details.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false); // Ensure loading is set to false even on error
    }
  };

  // Function to display the welcome message if no initial message
  const showWelcomeMessage = (details) => {
    const defaultWelcomeMessage = "Hi, how can I help you?";
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        content: details?.welcome_message || defaultWelcomeMessage,
        role: "system",
      },
    ]);
  };

  // New useEffect to send initial message after bot details are loaded
  useEffect(() => {
    const sendInitialMessage = async () => {
      if (!isLoading && initialMessage) {
        await handleSendMessage(initialMessage, true);
        setInitialMessage(null); // Reset initialMessage to prevent duplicate sending
      }
    };
    sendInitialMessage();
  }, [isLoading, initialMessage]);

  const handleSendMessage = async (messageText, isInitialMessage = false) => {
    const messageToSend = messageText || currentMessage.trim();
    if (
      !messageToSend ||
      (messages.length && messages[messages.length - 1].content === messageToSend)
    )
      return;

    setIsSending(true);
    setIsTyping(true); // Show typing indicator immediately

    if (!isInitialMessage) {
      // Only add user message to messages array if it's not the initial message
      const userMessage = { content: messageToSend, role: "user" };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
    }
    setCurrentMessage("");

    try {
      const response = await searchWithBotToken({
        query: messageToSend,
        bot_token: botToken,
      });

      const botMessage = {
        content: response.data?.response || "Error: No response from bot",
        role: "system",
        buttons: response.data?.buttons || [],
      };

      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setIsTyping(false); // Hide typing indicator after bot responds
      setIsSending(false);
    } catch (error) {
      console.error("Error sending message:", error);
      toast({
        title: "Error",
        description: "Failed to send message. Try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsTyping(false); // Hide typing on error
      setIsSending(false);
    }
  };

  const handleButtonClick = (button) => {
    if (button.action === "open_url" && button.url) {
      setIframeUrl(button.url); // Open the URL in an iframe within the app
    }
  };

  const closeIframe = () => {
    setIframeUrl(null); // Close the iframe by setting the URL to null
  };

  const inputWidth = useBreakpointValue({ base: "100%", md: "60%" });

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  if (isLoading) {
    return (
      <Flex height="100vh" justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  const botName = botDetails?.bot_name || "Bot";
  const botIconSrc = botDetails?.icon_base64
    ? `data:image/png;base64,${botDetails.icon_base64}`
    : null;

  return (
    <Flex height="100vh" flexDirection="column" backgroundColor="white">
      {/* Navigation Bar */}
      <Box
        as="nav"
        backgroundColor="teal"
        color="white"
        p={4}
        position="fixed"
        top="0"
        left="0"
        right="0"
        zIndex="1000"
      >
        <Flex justifyContent="center" alignItems="center">
          {botIconSrc ? (
            <Image
              src={botIconSrc}
              alt="Bot Icon"
              boxSize="40px"
              mr={4}
              borderRadius="full"
            />
          ) : (
            <Box
              boxSize="40px"
              bg="gray.500"
              borderRadius="full"
              mr={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
            >
              <FaRobot /> {/* Default icon if no image */}
            </Box>
          )}
          <Box fontSize="lg" fontWeight="bold">
            {botName}
          </Box>
        </Flex>
      </Box>

      {/* Main Content */}
      <Flex
        flex="1"
        flexDirection="column"
        mt="70px" // Height of the navbar
        mb="70px" // Height of the input area
        overflow="hidden"
      >
        {/* Messages Display */}
        <MessagesDisplay
          messages={messages}
          isTyping={isTyping}
          botDetails={botDetails}
          handleButtonClick={handleButtonClick}
        />

        {/* Iframe for Appointment Booking */}
        {iframeUrl && (
          <Box
            position="fixed"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="rgba(0, 0, 0, 0.6)" // Semi-transparent background
            zIndex="1100"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box position="relative" width="80%" height="80%">
              <iframe
                src={iframeUrl}
                style={{ width: "100%", height: "100%", border: "none" }}
                title="Book Appointment"
              />
              <Button
                position="absolute"
                top="10px"
                right="10px"
                colorScheme="red"
                onClick={closeIframe}
              >
                Close
              </Button>
            </Box>
          </Box>
        )}
      </Flex>

      {/* Message Input Area */}
      <Box
        position="fixed"
        bottom="0"
        left="0"
        right="0"
        p={4}
        backgroundColor="white"
        borderTop="1px solid #e2e8f0"
        zIndex="1000"
      >
        <Flex justifyContent="center" alignItems="center">
          <Flex width={inputWidth} alignItems="center">
            <Textarea
              ref={textareaRef}
              value={currentMessage}
              onChange={(e) => {
                setCurrentMessage(e.target.value);
                adjustTextareaHeight();
              }}
              onKeyDown={handleKeyDown}
              placeholder="Ask"
              bg="#f2f2f2"
              color="black"
              _placeholder={{ color: "gray.600" }}
              flex="1"
              mr={2}
              minHeight="2rem"
              maxHeight="150px"
              overflowY="auto"
              resize="none"
              boxSizing="border-box"
              padding="8px"
            />
            <Button
              onClick={() => handleSendMessage(currentMessage)}
              colorScheme="teal"
              isDisabled={isSending}
              rightIcon={isSending ? <Spinner size="sm" /> : null}
              minHeight="2rem"
              boxSizing="border-box"
            >
              {isSending ? "Sending" : "Send"}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default BotEmbedConversation;
